@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700;900&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

h1,
h2,
h3 {
  font-weight: 600 !important;
  margin: 0 !important;
  padding: 0 !important;
}

p {
  margin: 0 !important;
  padding: 0 !important;
}

a,
a:visited,
a:hover,
a:active,
a:link {
  text-decoration: none !important;
  /* color: inherit !important; */
}

:root {
  --white: #fff;
  --blue: #007bff;
  --dark-blue: #1a387c;
  --purple: #6f42c1;
  --red: #dc3545;
  --yellow: #ffc107;
  --green: #28a745;
  --secondary: #6ab175;
  --primary: #1a387c;

  --tertiary: #9dd8a2;

  --black: #1a1a1a;
  --black-light: #484848;
  --dark-grey: #989898;
  --grey: #c0c0c0;
  --dark-grey-light: #999;
  --light-grey: #f2f2f2;
  --step-color-purple: #b299c6;
  --step-color-white: #f0f7f5;
  --step-color-lite-red: #fce0dd;
  --step-color-green: #41b2a8;
  --step-color-red: #f2485b;
  --dark-theme: #10171f;
  --dark-theme-tertiary: #263143;
}

th {
  font-weight: bold !important;
}

.text-primary {
  color: var(--primary);
}

.App {
  max-width: 800px;
  margin: 0 auto;
  min-height: 100vh;
}

.background-image {
  min-height: 100vh;
  background-image: url('./images/bg-image.jpeg');
  background-position: center;
  background-repeat: no-repeat;
}

.content-certificate {
  min-height: 100vh;
  background-image: url('./images/bg-image2.jpeg');
  background-position: center;
  background-repeat: no-repeat;
}

.app-wrapper {
  min-height: 100vh;
  background-image: url('./images/bg-image.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.image-formatter {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-object-fit {
  object-fit: contain;
}
.normal-text {
  font-size: 14px;
}

.sm-text {
  font-size: 12px;
}

.small-text {
  font-size: 9px;
}

.smallest-text {
  font-size: 7px;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  padding: 20px;
}

.footer-bg {
  background-color: var(--primary);
  align-items: center;
}

.white-text-shadow {
  color: var(--primary); /* Set the text color to white */
  text-shadow: -1px -1px 5px #fff, /* Top left */ 1px -1px 5px #fff,
    /* Top right */ -1px 1px 5px #fff, /* Bottom left */ 1px 1px 5px #fff; /* Bottom right */
}

.rounded-button {
  display: inline-block;
  padding: 2px 10px;
  color: var(--primary);
  border: none;
  border-radius: 7px;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;
  width: 6rem;
}

.custom-button {
  display: inline-block;
  padding: 3.5px 10px;
  color: #fff;
  background-color: #182f80; /* Set the text color to white */
  border: none;
  border-radius: 6px; /* Set the border radius for a rounded shape */
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;
  width: 8rem;
}

.ant-modal-content {
  padding: 0px !important;
  border-radius: 10px !important;
}

.golden-bg {
  background-image: url('./images/bg-image2.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.5rem;
}

.ant-form-item .ant-form-item-label {
  padding: 0px !important;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

.text-size-name {
  font-size: 15px;
  font-weight: 900;
}

.text-size-score {
  font-size: 6.5px;
  font-weight: 600;
}
.logo-image {
  height: 40px;
}

.infinite-scale {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.infinite-scale:hover {
  transform: translateY(-10px);
}

.share-now-btn {
  width: 6.5rem;
}

.share-the-fun-text {
  font-size: 20px;
}

.share-now-btn {
  width: 130px;
}

.mobile-form-width {
  max-width: 81vw;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .share-the-fun-text {
    font-size: 14px;
  }

  .smallest-text {
    font-size: 9px;
  }
}

@media screen and (max-width: 800px) {
  .share-the-fun-text {
    font-size: 14px;
  }

  .smallest-text {
    font-size: 9px;
  }

  .share-the-fun-text {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) {
  .text-size-name {
    font-size: 25px;
    font-weight: 900;
  }

  .text-size-score {
    font-size: 12px;
  }

  .logo-image {
    height: 80px;
  }
  .share-now-btn {
    width: 8rem;
  }

  .normal-text {
    font-size: 16px;
  }

  .form-input-width {
    width: 270px;
  }

  .ant-row {
    display: grid;
    grid-template-columns: 200px 1fr;
  }
  .ant-select.ant-select-in-form-item {
    width: 94%;
  }
  .share-now-btn {
    width: 10rem;
  }

  .sm-text {
    font-size: 14px;
  }

  .mobile-form-width {
    margin: 0 1rem;
  }
}

@media only screen and (min-width: 830px) {
  .text-size-name {
    font-size: 40px;
    font-weight: 900;
  }

  .text-size-score {
    font-size: 16px;
  }

  .smallest-text {
    font-size: 13px;
  }
  .sm-text {
    font-size: 16px;
  }
}
