.content {
  padding: 1rem;
}
.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.logo {
  height: 64px;
  line-height: 64px;
  background-color: #fff;
}

.site-layout-background {
  background: #fff;
  width: 100%;
}

.sidebar-sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
}

.header-content-navbar {
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;

  font-weight: 700;
  font-size: 25px;
  height: 60px;
  display: flex;
  align-items: center;
  background: linear-gradient(120deg, #12cadf 0%, #1de4bd 100%);
}
.header-navbar-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  align-items: center;
}

.sidebar-menu-logo {
  margin-bottom: 2.5rem;
  height: 60px;
  background: #1dccdf;
}

/* .ant-menu-inline.ant-menu-root 
  .ant-menu-item, 
  .ant-menu-inline.ant-menu-root 
  .ant-menu-submenu-title, 
  .ant-menu-submenu-title:hover{
    color: #1dccdf;

  } */

/* .ant-menu-submenu-title:hover{
    color: #1dccdf;

  } */

.content-container {
  margin: 100px 30px 0 80px;
  overflow: initial;
}

.logout-container {
  color: #fff;
}

.sidebar-width-container-xcollapse {
  margin-left: 200px;
}
.sidebar-width-container-collapse {
  margin-left: 80px;
}

.logo-avatar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding-right: 20px;
}

.icons-styling {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.mobile-view-menu {
  display: none;
}

.ant-modal-footer,
.ant-modal-header {
  border: none !important;
}
.ant-modal-footer {
  padding-bottom: 2rem !important;
}

.spinner {
  height: 100vh;
  width: 100vw;
  background-color: #eeeeee;
}

.spinner-body {
  position: fixed;
  z-index: 1031;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1100px) {
  .sidebar-sider {
    width: 100px;
    min-width: 200px;
  }
}

@media (max-width: 850px) {
  .sidebar-sider {
    display: none;
  }

  .site-layout {
    margin-left: 0;
  }
  .sidebar-width-container-xcollapse {
    margin-left: -28px;
  }
  .sidebar-width-container-collapse {
    margin-left: 0px;
  }
  .laundro-logo {
    display: none;
  }

  .desktop-view-menu {
    display: none;
  }

  .mobile-view-menu {
    display: block;
  }
}

@media (max-width: 630px) {
  .sidebar-width-container-xcollapse {
    margin-left: -55px;
  }
  .sidebar-width-container-collapse {
    margin-left: 0px;
  }
}
